import React, { Component } from 'react';
import MaterialIcon from '@material/react-material-icon';
import PropTypes from 'prop-types';

class LoaderButton extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    text: PropTypes.string,
    loadingText: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    disabled: false,
    className: ''
  }

  render() {
    const { className, disabled, isLoading, text, loadingText } = this.props;

    return (
      <button
        className={`button button--loader ${className}`}
        disabled={disabled || isLoading}
      >
        {isLoading && <MaterialIcon icon="refresh" className="spinning" />}
        {!isLoading ? text : loadingText}
      </button>
    );
  }
}

export default LoaderButton;