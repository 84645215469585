import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const AppliedRoute = ({ component: C, props: cProps, ...rest }) =>
  <Route {...rest} render={props => <C {...props} {...cProps} />} />;


AppliedRoute.propTypes = {
  component: PropTypes.func,
  props: PropTypes.object
};

export default AppliedRoute; 
