import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
// import Home from './containers/Home';
import NotFound from './containers/NotFound';
import Login from './containers/Login';
import Dashboard from './containers/Dashboard';
import AppliedRoute from './components/Routes/AppliedRoute/AppliedRoute';
import AuthenticatedRoute from './components/Routes/AuthenticatedRoute/AuthenticatedRoute';
import UnauthenticatedRoute from './components/Routes/UnauthenticatedRoute/UnauthenticatedRoute';

const Routes = ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <AuthenticatedRoute path="/dashboard" exact component={Dashboard} props={childProps} />

    { /* Finally, catch all unmatched routes */ }

    <Route component={NotFound} />
  </Switch>;

Routes.propTypes = {
  childProps: PropTypes.object
};
  
export default Routes; 