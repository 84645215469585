import React, { Component } from 'react';
import LoaderButton from '../components/Buttons/LoaderButton/LoaderButton';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      password: ''
    };      
  }

  static propTypes = {
    userHasAuthenticated: PropTypes.func,
    history: PropTypes.object,
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      this.props.history.push('/dashboard');
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }  

  render() {
    return (
      <div className="login-page">
        <div className="hero-overlay"></div>
        <div className="login-container ">
          <form onSubmit={this.handleSubmit} className="form bs-lg">
            <div className="login-overlay"></div>
            <h2 className="title">Sign in.</h2>

            <div className="field">
              <label className="label">User</label>
              <input
                autoFocus
                type="text"
                className="input"
                value={this.state.email}
                onChange={this.handleChange}
                id="email"
              />
            </div>
            <div className="field">
              <label className="label">Password</label>
              <input
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
                className="input"
                id="password"
              />
            </div>
            <div className="field">
              <LoaderButton
                block
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Login"
                loadingText="Logging in…"
                className="button button--outline"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
