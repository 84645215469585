export default {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'GATEWAY_URL'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_huajIO4Bu',
    APP_CLIENT_ID: '22uq823nlinm53kjb3to7dg8ob',
    IDENTITY_POOL_ID: 'us-east-1:4f6e48cd-5111-4502-9fe0-776574c05fb9'
  },
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'rpw-data131319-dev'
  }
};
