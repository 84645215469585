import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
// import Header from './components/Header';
// import Footer from './components/Footer';
import Routes from './Routes';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  static propTypes = {
    history: PropTypes.object,
  }
  
  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut(event);
    this.userHasAuthenticated(false);
    this.props.history.push('/login');
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      title: 'RPW',
      handleLogout: this.handleLogout
    };

    return (
      !this.state.isAuthenticating &&
      <div className="grid--fluid">
        <section className="site-content">
          <Routes childProps={childProps} />
        </section>
      </div>
    );
  }
}

export default withRouter(App);
export { App as TestableApp };