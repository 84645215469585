import React, { Component } from 'react';
import { Storage } from 'aws-amplify';


import {
  ComposedChart,
  Line, ReferenceLine,
  Area,
  ResponsiveContainer,
  XAxis, YAxis,
  PieChart, Pie, Sector,
  CartesianGrid
} from 'recharts';

import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';

import sampleJson from '../data/details.json';

const mockData = false;

// const sampleJson = {
//   "goal": 120,
//   "records": [{
//     "day": 1,
//     "date": "2020-01-20",
//     "weight": 200,
//     "exercise": false,
//     "alcohol": false,
//   },
//   {
//     "day": 2,
//     "date": "2020-01-21",
//     "weight": 198,
//     "exercise": true,
//     "alcohol": false,
//   },
//   {
//     "day": 3,
//     "date": "2020-01-22",
//     "weight": 196,
//     "exercise": true,
//     "alcohol": false,
//   },
//   {
//     "day": 4,
//     "date": "2020-01-23",
//     "weight": 197,
//     "exercise": true,
//     "alcohol": false,
//   }]
// };

export default class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      panel: 'overview',
      activeDate: this.formatDate(Date().now),
      goalSubtractor: .2,
      projectedDate: '',
      todaysRecord: {}

    };

    this.updateRecord = this.updateRecord.bind(this);
    this.incrementAlcohol = this.incrementAlcohol.bind(this);
  }
  
  formatDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  getRecordIndex(date) {
    return _.findIndex(this.state.data.records, function(o) { return o.date === date; });
  }

  getRecord() {
    let recordIndex = this.getRecordIndex(this.formatDate(this.state.activeDate));

    if(recordIndex >= 0) {
      return this.state.data.records[recordIndex];
    } 

    return '';
  }

  getWeight() {
    return this.getRecord().weight;
  }

  componentDidMount() {
    if(mockData) {
      this.setupJson(sampleJson);
    } else {
      this.retrieveJson();
    }
  }

  retrieveJson() {
    Storage.get('rpw/details.json')
           .then((result) => {
              axios.get(result)
                   .then((response) => { this.setupJson(response.data);  })
                   .catch((err) => { console.log(err); })
    })
    .catch(err => console.log(err));
  }

  resetJson(element) {
    element.preventDefault();
    let weight = element.target[0].value;
    let goal = element.target[1].value;
    
    let json = {
      "goal": goal,
      "records": [{
        "day": 1,
        "date": this.formatDate(moment()),
        "weight": weight, 'goal': weight, "proj": weight, 
        "exercise": false, "alcohol": false,
        "yest": 0, "diff": 0, "total": 0, "avg": 0, "pct": 0
      }]
    }

    json.records = this.fillInMissingDays(json);
    json.records = this.calculateStatistics(json.records);
    this.setState({ data: json, panel: 'daily' });
    this.saveJson(json);
  }

  saveJson(json) {
    if(!mockData) {
      if(!json) {
        json = this.state.data;
      }

      Storage.put('rpw/details.json', JSON.stringify(json, null, 2))
            .then (result => console.log(result)) 
            .catch(err => console.log(err));
    }
  }

  today() {
    return this.formatDate(moment());
  }

  setupJson(data) {
    console.log('hi')
    console.log(data);
    let newDataSet = data;

    // console.log(JSON.stringify(data, null, 2));

    newDataSet.records = this.fillInMissingDays(data);
    newDataSet.records = this.calculateStatistics(newDataSet.records);

    let today = this.today();
    let recordIndex = _.findIndex(newDataSet.records, function(o) { return o.date === today; });

    let startingWeight = parseFloat(newDataSet.records[0].weight);
    let currentWeight = parseFloat(newDataSet.records[recordIndex].weight);
    let goalWeight = parseFloat(newDataSet.goal);

    let percentComplete = ((startingWeight - currentWeight) / (startingWeight - goalWeight)) * 100;

    let goalComplete =[
      { name: 'Remaining', value: (100 - percentComplete), fill: '#fafafa', opacity: '.2' },
      { name: 'Complete', value: percentComplete, fill: '#fafafa' },
    ];

    let projDate = "NEVER";
    let currentRecord = newDataSet.records[recordIndex];

    if(currentRecord.avg < 0) {
      let projDays = Math.abs(Math.ceil((parseFloat(currentRecord.weight) - parseFloat(data.goal)) / currentRecord.avg));//(moment().diff(moment(last.date), 'days')) + 30;
      projDate = moment(currentRecord.date).add(projDays, 'd').format('MM/DD/YYYY');
    }

    this.setState({ data: newDataSet, 
                    goalCompleteData: goalComplete,
                    currentDateIndex: recordIndex,
                    projectedDate: projDate  })
  }

  calculateGoalDate() {
    let recs = this.state.data.records;
    var last = recs[recs.length - 1];
    let daysBehind = Math.ceil((parseFloat(last.weight) - parseFloat(this.state.data.goal)) / this.state.goalSubtractor);
    return moment().add(daysBehind, 'days');
  }

  fillInMissingDays(data) {
    let recs = data.records;

    let today = this.today();
    let todaysIndex = _.findIndex(recs, function(o) { return o.date === today; });
    recs.length = todaysIndex + 1;

    var last = recs[recs.length - 1];

    let daysBehind = 30 //Math.ceil((parseFloat(last.weight) - parseFloat(data.goal)) / this.state.goalSubtractor);//(moment().diff(moment(last.date), 'days')) + 30;

    for(let day = 1; day <= daysBehind; day++) {
      let hash = {
        "day": last.day + day,
        "date": this.formatDate(moment(last.date).add(day, 'days')),
        "weight": last.weight,
        "exercise": false,
        "alcohol": false
      };

      recs.push(hash);
    }

    return recs;
  }

  calculateStatistics(recs = this.state.data.records) {
    let prevRecord = recs[0];
    let weightDifference = [0];

    recs[0]['proj'] = recs[0]['weight'];

    let today = moment().startOf('day');
    let projAvg = 0;

    recs.forEach((rec, index) => {
      recs[index]['daysnoalcohol'] = 0;
      if(index > 0) {
        weightDifference.push(rec.weight - prevRecord.weight);
        let avg = this.sum(weightDifference) / weightDifference.length;        

        if(!prevRecord.goal) {
          prevRecord.goal = prevRecord.weight;
        }

        if(!prevRecord.projWeight) {
          prevRecord.projWeight = prevRecord.weight;
        }

        if(moment(rec.date).isAfter(today)) {
          recs[index]['weight'] = prevRecord.weight;
        }

        if(moment(rec.date).isSameOrBefore(today)) {
          projAvg = rec.avg;
        } 

        recs[index]['goal'] = (parseFloat(prevRecord.goal) - this.state.goalSubtractor).toFixed(2);
        recs[index]['proj'] = (parseFloat(prevRecord.proj) + avg).toFixed(2);
        recs[index]['projWeight'] = (parseFloat(prevRecord.projWeight) + parseFloat(projAvg)).toFixed(2);
        recs[index]['yest'] = (parseFloat(rec.weight) - parseFloat(prevRecord.weight)).toFixed(2);
        recs[index]['diff'] = (parseFloat(rec.goal) - parseFloat(rec.weight)).toFixed(2);
        recs[index]['total'] = (parseFloat(rec.weight) - parseFloat(recs[0].weight)).toFixed(2);
        recs[index]['avg'] = avg.toFixed(2);
        recs[index]['pct'] = (((parseFloat(recs[0].weight) - parseFloat(rec.weight)) / parseFloat(recs[0].weight)) * 100).toFixed(2);
        
        if(rec.alcohol === false || parseInt(rec.alcohol) === 0) {
          recs[index]['daysnoalcohol'] = parseInt(prevRecord.daysnoalcohol) + 1;
        } else {
          recs[index]['daysnoalcohol'] = 0;
        } 

        prevRecord = rec;
      }
    });

    return recs;
  }

  sum(ary) {
    return ary.reduce((a, b) => a + b, 0);
  }

  dayBefore() {
    if(!this.disableBack()) {
      this.setState({
        activeDate: this.formatDate(moment(this.state.activeDate).subtract(1, 'days'))
      });
    }
  }

  dayAfter() {
    if(!this.disableForward()) {
      this.setState({
        activeDate: this.formatDate(moment(this.state.activeDate).add(1, 'days'))
      });
    }
  }

  disableBack() {
    return this.getRecordIndex(this.formatDate(this.state.activeDate)) === 0;
  }

  disableForward() {
    return this.state.activeDate === this.formatDate(moment());
  }

  removeWeight() {
    this.updateRecord('weight', (parseFloat(this.getWeight()) - .2).toFixed(1));
  }

  addWeight() {
    this.updateRecord('weight', (parseFloat(this.getWeight()) + .2).toFixed(1));
  }

  updateRecord(key, value) {
    let index = this.getRecordIndex(this.state.activeDate);
    let data = this.state.data;
    
    data.records[index][key] = value;

    data.records = this.fillInMissingDays(data);
    data.records = this.calculateStatistics(data.records);
    this.saveJson(data);

    this.setState({
      data: data
    });
  }

  getBooleanSelect(key, icon = "thumb_down", func) {

    let currentValue = this.getRecord()[key];
    let value = !currentValue;
    if(func) {
      value = func(key);
      if(key === 'alcohol' && typeof currentValue === "boolean") {
        currentValue = currentValue ? 1 : 0;
      }
    }

    return(
      <div className={key + ` toggle-container state-` + currentValue} onClick={(e) => { this.updateRecord(key, value) }}>
        <i className="material-icons">{icon}</i> 
        <div className="value">{currentValue}</div>
      </div>
    )
  }

  incrementAlcohol(key) {
    let value = this.getRecord()[key];

    if(value === false) {
      value = 0;
    } else if(value === true) {
      value = 1;
    } else {
      value = parseInt(value) + 1;
    }

    if(value === 10) {
      value = 0;
    }

    return value;
  }

  formatXAxis(tickItem) {
    return moment(tickItem).format('MM/DD');
  }

  formatYAxis(tickItem) {
    return Math.ceil(parseFloat(tickItem));
  }

  dashboardWeightLoss() {
    return (parseFloat(this.state.data.records[this.state.currentDateIndex].weight) - parseFloat(this.state.data.records[0].weight)).toFixed(1);
  }

  renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
  
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={ innerRadius - 4 }
          outerRadius={ outerRadius + 3 }
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  render() {
    return (
      <div className={`full-page-graph bg-` + this.state.panel }>
        <div className="main-container">
          {
            this.state.panel === 'overview' &&
              <div className="chart-container">
                <div className="page-header">RPW</div>

                <div className="pie-wrap">
                  <ResponsiveContainer width="100%" height={200}>
                    <PieChart>
                      <Pie data={ [{ value: 100 }] } dataKey="value" outerRadius={70} fill="#fafafa" />
                      <Pie
                        data={ this.state.goalCompleteData }
                        innerRadius={87}
                        outerRadius={90}
                        startAngle={90}
                        endAngle={470}
                        dataKey="value"
                        activeIndex={1}
                        activeShape={this.renderActiveShape}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                  
                  { 
                    this.state.goalCompleteData && 
                      <div className="percentage">
                        <div className="pre">Loss</div>
                        <div className="value">{ this.state.goalCompleteData[1].value.toFixed(1) }%</div>
                      </div>
                  }

                </div>

                { 
                  this.state.data.records &&
                    <div className="stats-grid grid mgb-sm">
                      <div className="grid-fluid">
                        <div className="col col-small-3">
                          <div className="title">day</div>
                          <div className="value">{ this.state.data.records && this.state.data.records[this.state.currentDateIndex].day }</div>
                        </div>
                        <div className="col col-small-3">
                          <div className="title">total loss</div>
                          <div className="value"><span className={ (this.dashboardWeightLoss() >= 0 ? 'bad' : 'good') }>{ this.dashboardWeightLoss() }</span></div>
                        </div>
                        <div className="col col-small-3">
                          <div className="title">average</div>
                          <div className="value">{ this.state.data.records[this.state.currentDateIndex].avg }</div>
                        </div>
                        <div className="col col-small-3">
                          <div className="title">dry</div>
                          <div className="value"><span>{ this.state.data.records && this.state.data.records[this.state.currentDateIndex].daysnoalcohol }</span></div>                        
                        </div>
                      </div>
                      <div className="grid-fluid">
                        <div className="col col-small-2">
                          <div className="title">goal</div>
                          <div className="value">{ this.state.data.records && this.state.data.goal }</div>
                        </div>
                        <div className="col col-small-5">
                          <div className="title">goal date</div>
                          <div className="value">{ this.state.data.records && this.calculateGoalDate().format('MM/DD/YYYY') }</div>
                        </div>
                        <div className="col col-small-5">
                          <div className="title">projected date</div>
                          <div className={`value ` + (this.state.projectedDate === 'NEVER' ? "bad" : (moment(this.state.projectedDate).isBefore(moment(this.state.data.records[this.state.data.records.length - 1].date)) ? "good" : ""))}>{ this.state.projectedDate }</div>
                        </div>
                      </div>
                    </div>
                }
                
                <div className="trend-graph">
                  {/* <div className="legend">
                    <span className="legend-item weight">Weight</span>
                    <span className="legend-item projected">Projected</span>
                    <span className="legend-item goal">Goal</span>
                  </div> */}
                  <div className="chart">
                    <ResponsiveContainer>
                      <ComposedChart data={this.state.data.records}
                                     margin={{top: 30, right: 0, left: 30, bottom: 0}}>
                        
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        
                        <XAxis dataKey="date" interval="preserveStart" tick={{ fontSize: 10 }} tickMargin="10" minTickGap={30} tickFormatter={this.formatXAxis} />
                        
                        <YAxis domain={['dataMin - 5', 'dataMax + 5']} tick={{fontSize: 10}} tickMargin="5" orientation="right" tickFormatter={this.formatYAxis} />
                        
                        <Line type="monotone" dataKey="projWeight" stroke="#e8a87c" strokeDasharray="4 2" dot={false} />

                        <Line type="monotone" dataKey="goal" stroke="#777" strokeDasharray="3 3" dot={false} />
                        <Line type="monotone" dataKey="proj" stroke="#3CA395" dot={false} strokeWidth={2} />

                        <Area type="monotone" dataKey="weight" fill="#41b3a3" stroke="#41b3a3" opacity=".3" dot={false} strokeWidth={1} />
                        
                        <ReferenceLine x={ this.formatDate(moment()) } strokeDasharray="3 3" stroke="#F25F5C" label={ { value: moment().format('M/D'), position: "top", offset: "20", fontSize: "10px", fill: "#555"  }} />
                        <ReferenceLine x={ this.formatDate(moment()) } strokeDasharray="3 3" stroke="#F25F5C" label={ { value: this.state.data.records ? this.state.data.records[this.state.currentDateIndex].weight : 'TBD', position: "top", offset: "5", fontSize: "10px", fill: "#000"  }} />

                      </ComposedChart> 
                    </ResponsiveContainer>   
                  </div> 
                </div>
              </div>  
          }

          {
            this.state.panel === 'daily' && 
            <div className="">
              <div className="grid page-header">
                <div className="navigation col-12">
                  <span className={`item ` + (this.disableBack() ? "disabled" : "")} onClick={(e) => { this.dayBefore(e) }}><i className="material-icons">arrow_back</i></span>
                  <span className={`item ` + (this.disableForward() ? "disabled" : "")} onClick={(e) => { this.dayAfter(e) }}><i className="material-icons">arrow_forward</i></span>
                </div>
                <div className="title">{ moment(this.state.activeDate).format('MMMM Do, YYYY') }</div>
              </div>
              <div className="grid weight-card">
                <div className="col-4 col-small-4 bg">
                  <div className="card-title">Weight</div>
                  <div className="value">{ this.getWeight() }</div>
                </div>
                <div className="col-6 col-small-6 bg icons text-align-right">
                  <i className="material-icons" onClick={(e) => { this.removeWeight(e) }}>remove_circle_outline</i>
              
                  <i className="material-icons" onClick={(e) => { this.addWeight(e) }}>add_circle_outline</i>
                </div>
                <div className="col"></div>
              </div>
              <div className="stats grid">
                {
                  ['goal', 'proj', 'yest', 'total'].map((item, index) => {
                    let record = this.getRecord();

                    return (
                      <div key={index} className="stat col-6 col-small-6">
                        <div className="card-title">{item}</div>
                        <div className="value">{ record[item] }</div>
                      </div>
                    )
                  })
                }
                
              </div>
              <div className="pdl-md grid grid--gap">
              {
                [{ key: 'exercise', icon: 'directions_run' },
                 { key: 'alcohol', icon: 'local_bar', function: this.incrementAlcohol }].map((item, index) => {
                  return (
                    <div key={index} className="col-4 col-small-4 toggle-card">
                      <div className="card-title">{item.key}</div>
                      { this.getBooleanSelect(item.key, item.icon, item.function) }
                    </div>
                  )
                })
              }
                <div className="col"></div>
              </div>
            </div>
          }  

          {
            this.state.panel === 'settings' &&
              <div className="grid grid--column grid--center-center">
                <form className="form" onSubmit={(e) => { this.resetJson(e) }}>
                  <div className="field col-offset-3 col-small-6">
                    <label className="label" htmlFor="weight">Weight</label>
                    <input type="text" className="input" id="weight" />
                  </div>
                  <div className="field col-offset-3 col-small-6">
                    <label className="label" htmlFor="goal">Goal</label>
                    <input type="text" className="input" id="goal" />
                  </div>

                  <div className="field col-offset-3 col-small-6 mgt-lg">
                    <button type="submit" className="button button--outline">Reset</button>
                  </div>
                </form>
              </div>
          }          
        </div>
        <div className="menu grid-fluid">
          {
            ['overview', 'daily', 'settings'].map((item, index) => {
              return (
                <div key={index} className={`menu-item col ` + (this.state.panel === item ? 'active' : '')} onClick={(e) => { this.setState({ panel: item }) }}>{item}</div>
              )
            })
          }
        </div>
      </div>
    );
  }
}